<template lang="pug">
section.section(v-if='collection.length > 0 || isLoading')
  bl-product-scroller-ov.u-pad-top--4(
    title='Pilihan Lain Untukmu',
    impression-name='sem_track_related',
    :collection='collection',
    :is-loading='isLoading',
    :impression-tracker-data='impressionTrackerData',
    @productClick='handleProductClick'
  )
</template>

<script>
import { feedMnm, promotedPushProducts } from '~pdp/js/modules/api-modules';

const BlProductScrollerOv = () =>
  import(/* webpackChunkName: "bl-product-scroller-ov" */ './components/BlProductScrollerOv');

const MIN_PROMOTED_COUNT = 6;

export default {
  name: 'ProductRecoPromoted',

  components: { BlProductScrollerOv },

  props: {
    productId: {
      type: Number,
      default: 0,
    },
    referrerPage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentProduct: this.productId,
      isLoading: false,
      collection: [],
    };
  },

  computed: {
    productIdBase36() {
      return this.currentProduct.toString(36);
    },
    userId36() {
      return (
        (global.USER && global.USER.id36) ||
        (global.blGlobal && global.blGlobal.user && global.blGlobal.user.id_36) ||
        ''
      );
    },
    impressionTrackerData() {
      return {
        product_id_source: this.productIdBase36,
        evn: 'viewed_promoted_product',
        section: 'desktop product other reco',
        ui: this.userId36,
      };
    },
  },

  mounted() {
    this.fetchCollection();
    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach(ev => {
        global.pdpEventBus.subscribe(ev, this.handleChangeProduct);
      });
    }
  },

  beforeDestroy() {
    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach(ev => {
        global.pdpEventBus.unsubscribe(ev, this.handleChangeProduct);
      });
    }
  },

  methods: {
    async fetchCollection() {
      try {
        this.isLoading = true;
        const { data } = await feedMnm.retrieveProductRecommendationsWithoutSubtitleInfo({
          queryParams: {
            product_id: this.productIdBase36,
            limit: 18,
            strategy: 'prom',
            referrer_page: this.referrerPage,
          },
        });
        if (data.length >= MIN_PROMOTED_COUNT) {
          this.collection = data.map(item => item.product);
        }
      } catch (e) {
        /** */
      } finally {
        this.isLoading = false;
      }
    },

    charge(product) {
      promotedPushProducts.$charge.chargeProductSPromotedPush(product.id, {
        data: { section: 'desktop product other reco' },
        headers: { 'X-Identity': global.IDENTITY },
      });
    },

    handleProductClick(product) {
      this.charge(product);
    },

    handleChangeProduct(e) {
      this.currentProduct = parseInt(e.detail.product.id, 36);
      this.fetchCollection();
    },
  },
};
</script>
