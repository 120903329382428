import VueGlobal from '~shared-js/vue-global';

import ProductRecoOther from './ProductRecoPromoted';

const { Vue } = window;
const APP_ID_SELECTOR = '#product-recommendation-other';
VueGlobal(Vue);

let app;
const initialize = function initialize() {
  const el = document.querySelector(APP_ID_SELECTOR);
  const { productId, referrer } = (el && el.dataset) || {};

  if (app) {
    app.$destroy();
  }
  if (productId) {
    app = new Vue({
      el: APP_ID_SELECTOR,
      name: 'ProductRecoRoot',
      render: h =>
        h(ProductRecoOther, {
          props: {
            productId: Number(productId),
            referrerPage: referrer,
          },
        }),
    });
  }
};

const obs = new IntersectionObserver(entries => {
  if (entries[0].isIntersecting) {
    initialize();
  }
});
obs.observe(document.querySelector(APP_ID_SELECTOR));
